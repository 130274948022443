<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" @tab-click='handleClick'>
        <el-tab-pane label="公有频道" name="first">
            <el-card shadow="never">
                <!-- 搜索表单 -->
                <el-form :model="table.where" class="ele-form-search d-flex"
                        @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
                  <el-form-item label="省:" label-width="35px" class="w-170">
                    <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
                      <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="市:" label-width="35px" class="w-150">
                    <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
                      <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="县/区:" label-width="62px" class="w-200">
                    <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                      <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
										<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                    <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
									</el-form-item>
                </el-form>
                <!-- 数据表格 -->
                <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
                    <template slot-scope="{index}">
                        <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                        <el-table-column prop="number" label="频道号"  show-overflow-tooltip min-width="100"/>
                        <el-table-column prop="name" label="频道名称"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="image" label="频道头像"  show-overflow-tooltip min-width="60">
													<template slot-scope="{row}">
															<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
																<div slot="error" class="image-slot">
																	<i class="el-icon-picture-outline"></i>
																</div>
															</el-image>
													</template>
                        </el-table-column>
                        <el-table-column prop="area" label="频道地区"  show-overflow-tooltip min-width="200"/>
                        <el-table-column prop="content" label="频道介绍"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="user_num" label="频道人数"  show-overflow-tooltip min-width="60"/>
                        <el-table-column prop="create_time" label="创建时间"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="open" label="频道状态"  show-overflow-tooltip min-width="60">
                            <template slot-scope="scope">
                              {{scope.row.open==1?'暂停':'开启'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="240px" align="center" :resizable="false">
                            <template slot-scope="{row}">
                                <el-link slot="reference" @click="pause_start(row)" :icon="row.open==1?'el-icon-s-claim':'el-icon-remove-outline'" :type="row.open==1?'primary':'danger'" :underline="false" v-if="permission.includes('sys:intercom_lightning:start')">
                                    {{row.open==1?'开启':'暂停'}}
                                </el-link>
                                <el-link icon="el-icon-edit" type="primary" :underline="false" @click="edit(row)" v-if="permission.includes('sys:intercom_lightning:edit')">编辑</el-link>
                                <el-link icon="el-icon-user-solid" type="primary" :underline="false" @click="handleWatch(row)" v-if="permission.includes('sys:intercom_lightning:viewuser')">查看频道成员</el-link>
                            </template>
                        </el-table-column>
                    </template>
                </ele-data-table>
            </el-card>
        </el-tab-pane>
        <el-tab-pane label="私有频道" name="second">
            <el-card shadow="never">
                <!-- 搜索表单 -->
                <el-form :model="table1.where" class="ele-form-search d-flex f-end"
                        @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
                    <el-form-item label="省:" label-width="35px" class="w-170">
                      <el-select v-model="table1.where.pid"  @change="handleChangeProv(table1.where.pid)" placeholder='请选择省' clearable>
                        <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="市:" label-width="35px" class="w-150">
                      <el-select v-model="table1.where.cid"  @change="handleChangeCity(table1.where.cid)" placeholder='请选择市' clearable>
                        <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="县/区:" label-width="62px" class="w-200">
                      <el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                        <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="频道名称:" label-width="90px">
                        <el-input v-model="table1.where.name" placeholder="请输入频道名称" class="drop-down-fourth" clearable/>
                    </el-form-item>
                    <el-form-item label="群主姓名:" label-width="90px">
                        <el-input v-model="table1.where.phone_name" placeholder="请输入群主姓名" class="drop-down-fourth" clearable/>
                    </el-form-item>
                    <el-form-item label="频道号:" label-width="70px" >
                        <el-input v-model="table1.where.number" placeholder="请输入频道号" class="drop-down-fourth" clearable/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                      <el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
                    </el-form-item>
                </el-form>

                <!-- 数据表格 -->
                <ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
                    <template slot-scope="{index}">

                        <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                        <el-table-column prop="number" label="频道号"  show-overflow-tooltip min-width="100"/>
                        <el-table-column prop="name" label="频道名称"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="image" label="频道头像"  show-overflow-tooltip min-width="60">
                            <template slot-scope="{row}">
															<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
																<div slot="error" class="image-slot">
																	<i class="el-icon-picture-outline"></i>
																</div>
															</el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone_name" label="群主"  show-overflow-tooltip min-width="200"/>
                        <el-table-column prop="area" label="频道地区"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="content" label="频道介绍"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="user_num" label="频道人数"  show-overflow-tooltip min-width="60"/>
                        <el-table-column prop="create_time" label="创建时间"  show-overflow-tooltip min-width="120"/>
                      <el-table-column prop="open" label="频道状态"  show-overflow-tooltip min-width="60">
                        <template slot-scope="scope">
                          {{scope.row.open==1?'暂停':'开启'}}
                        </template>
                      </el-table-column>
                        <el-table-column label="操作" width="240px" align="center" :resizable="false">
                            <template slot-scope="{row}">
                                <el-link slot="reference" @click="pause_start(row)" :icon="row.open==1?'el-icon-s-claim':'el-icon-remove-outline'" :type="row.open==1?'primary':'danger'" :underline="false" v-if="permission.includes('sys:intercom_lightning:si_start')">
                                    {{row.open==1?'开启':'暂停'}}
                                </el-link>
                                <el-link icon="el-icon-view" type="primary" :underline="false" @click="edit(row)" v-if="permission.includes('sys:intercom_lightning:si_edit')">编辑</el-link>
                                <el-link icon="el-icon-user-solid" type="primary" :underline="false" @click="handleWatch(row)" v-if="permission.includes('sys:intercom_lightning:si_viewuser')">查看频道成员</el-link>
                            </template>
                        </el-table-column>
                    </template>
                </ele-data-table>
            </el-card>
        </el-tab-pane>
        <el-tab-pane label="频道审核" name="third">
            <el-card shadow="never">
                <!-- 搜索表单 -->
                <el-form :model="table2.where" class="ele-form-search  d-flex f-end" width="100px"
                        @keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
                  <el-form-item label="省:" label-width="35px" class="w-150">
                    <el-select v-model="table2.where.pid"  @change="handleChangeProv(table2.where.pid)" placeholder='请选择省' clearable>
                      <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="市:" label-width="35px" class="w-150">
                    <el-select v-model="table2.where.cid"  @change="handleChangeCity(table2.where.cid)" placeholder='请选择市' clearable>
                      <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="县/区:" label-width="62px" class="w-200">
                    <el-select v-model="table2.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                      <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                    </el-select>
                  </el-form-item>
                    <el-form-item label="申请人:" label-width="60px">
                        <el-input v-model="table2.where.phone_name" placeholder="请输入申请人" class="drop-down-third" clearable/>
                    </el-form-item>
                    <el-form-item label="开始时间:" label-width="76px">
                        <el-date-picker v-model="table2.where.create_time" type="date" placeholder="搜索开始时间" clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间:" label-width="76px">
                        <el-date-picker v-model="table2.where.end_time" type="date" placeholder="搜索结束时间" clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态:" label-width="50px">
                        <el-select v-model="table2.where.type" placeholder="审核状态" class="drop-down-third" clearable>
                            <el-option  value="0" label="待审核">待审核</el-option>
                            <el-option  value="1" label="已通过">已通过</el-option>
                            <el-option  value="2" label="已拒绝">已拒绝</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                      <el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
                    </el-form-item>
                </el-form>

                <!-- 数据表格 -->
                <ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
                    <template slot-scope="{index}">
                        <el-table-column type="selection" width="45" align="center" />
                        <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                        <el-table-column prop="number" label="频道号"  show-overflow-tooltip min-width="140"/>
                        <el-table-column prop="name" label="频道名称"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="image" label="频道头像"  show-overflow-tooltip min-width="120">
                            <template slot-scope="{row}">
															<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
																<div slot="error" class="image-slot">
																	<i class="el-icon-picture-outline"></i>
																</div>
															</el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone_name" label="群主"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="area" label="频道地区"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="content" label="频道介绍"  show-overflow-tooltip min-width="120"/>
                        <el-table-column prop="create_time" label="申请时间"  show-overflow-tooltip min-width="120"/>
												<el-table-column prop="image" label="审核状态"  show-overflow-tooltip min-width="120">
													<template slot-scope="{row}">
														{{row.type==0?'待审核':row.type==1?'已通过':'已拒绝'}}
													</template>
												</el-table-column>
                        <el-table-column label="操作" show-overflow-tooltip min-width="120" align="center">
                            <template slot-scope="{row}">
															<span v-if="row.type!==0">已审核</span>
															<el-link v-if="row.type==0 && permission.includes('sys:intercom_lightning:pass')" icon="el-icon-success" type="primary" :underline="false" @click="handlePass(row)" >通过</el-link>
															<el-link v-if="row.type==0 && permission.includes('sys:intercom_lightning:reject')" icon="el-icon-error" type="danger" :underline="false" @click="handleReject(row)" >驳回</el-link>
                            </template>
                        </el-table-column>
                    </template>
                </ele-data-table>
            </el-card>
        </el-tab-pane>
        <!-- 对话框：编辑 -->
        <el-dialog v-dialogDrag title="编辑频道" :visible.sync="dialogVisibleEditor" :destroy-on-close="true" :lock-scroll="false">
          <el-card shadow="never">
            <el-form :model="editForm" label-width="170px">
              <el-form-item label="频道名称:"  class="drop-down-second">
                <el-input v-model="editForm.name" autocomplete="off" @input="input"></el-input>
              </el-form-item>
           
              <el-form-item label="封面图：" >
                <uploadImage :limit="1" v-model="editForm.image" ></uploadImage>
              </el-form-item>
              <el-form-item label="频道介绍:">
                <el-input type="textarea" :rows="4" v-model="editForm.content"  @input="input" class="drop-down-second"></el-input>
              </el-form-item>
							<div v-if="editForm.is_public == 2">
								<el-form-item label="可创建频道数量:"  class="drop-down-second">
									<el-input v-model="editForm.channel_num" autocomplete="off" @input="input"></el-input>
								</el-form-item>
							</div>
            </el-form>
          </el-card>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleEditor=false">取消</el-button>
            <el-button type="primary" @click="save">确定提交</el-button>
          </div>
      </el-dialog>
        <!-- 对话框：查看 -->
        <el-dialog v-dialogDrag title="频道列表" :visible.sync="dialogVisibleView" :destroy-on-close="true" :lock-scroll="false">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table3.where" class="ele-form-search  d-flex f-end"
                    @keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
                <el-form-item label="司机姓名:" label-width="80px">
                    <el-input v-model="table3.where.name" placeholder="请输入司机姓名" class="drop-down-third" clearable/>
                </el-form-item>
                <el-form-item label="车牌号:" label-width="70px">
                    <el-input v-model="table3.where.car_number" placeholder="请输入车牌号" class="drop-down-third" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                  <el-button @click="(table3.where={})&&$refs.table3.reload()">重置</el-button>
                </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table3" :config="table3" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center"/>
                    <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                    <el-table-column prop="image" label="头像"  show-overflow-tooltip min-width="50">
                        <template slot-scope="{row}">
													<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
													</el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="true_name" label="姓名"  show-overflow-tooltip min-width="100"/>
                    <el-table-column prop="car_number" label="车牌号"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="total_time" label="地区"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="total_time" label="状态"  show-overflow-tooltip min-width="120"/>
                    <el-table-column prop="total_time" label="可创建频道数量"  show-overflow-tooltip min-width="120"/>

                </template>
            </ele-data-table>
          </el-card>
        </el-dialog>
    </el-tabs>
<Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uploadImage from "@/components/uploadImage";
import Footer from "../../../components/footer"
export default {
    name: "SysUser",
  components: {uploadImage,Footer},
    data() {
        return {
        table: {url: '/voice/channel', where: {public:1}},  // 表格配置
        table1: {url: '/voice/channel', where: {public:2}},  // 表格配置
        table2: {url: '/voice/channelAudit', where: {}},  // 表格配置
        choose: [],  // 表格选中数据
        editForm: { id:'',name:'',image:'',content:''},  // 表单数据
        checkForm:{},
        dialogTableVisibleCheck:false,
        dialogTableVisibleEdit:false,
        dialogTableVisibleUrgent:false,
        provArr:[],
        cityArr:[],
        districtArr:[],
        urgentList:[],
        imageUrl:false,
        headImg:'',
        activeName:'first',
        active:1,
        dialogVisibleEditor:false,
        dialogVisibleClosevoice:false,
        dialogVisibleOpenvoice:false,
        dialogVisibleView:false,
        dialogVisiblePass:false,
        dialogVisibleReject:false,
        a:'',
     
        // imageUrl: '',
          list:[],
          table3:{url:'/voice/channelDriver',where:{}}

        }
    },
    created(){
        this.$http.get('/common/province_list').then(res=>{
            let data=JSON.parse(res.data)
            this.provArr=data
            })
    },

    computed: {
        ...mapGetters(["permission"]),
    },
    mounted() {
    },
    methods: {
      handleClick(e){
        if(e.name=='first'){
          this.active=1
        }else if(e.name=='second'){
          this.active=2
        }else{
          this.active=3
        }
      },
      handleWatch(row){
        this.dialogVisibleView=true
        this.table3.where.id=row.id
      },
      input(){this.$forceUpdate()},
      edit(row){
        this.dialogVisibleEditor=true
        this.editForm.id=row.id
        this.editForm.name=row.name
        this.editForm.image=row.image
        this.editForm.content=row.content
        this.editForm.uid=row.uid
        this.editForm.channel_num=row.channel_num
        this.editForm.is_public=row.public
      },
      save(){

      this.dialogVisibleEditor = false
      const loading = this.$loading({lock: true});
        this.$http.post('/voice/channelEdit',this.editForm).then(res=>{
        loading.close();
        let data=res.data
          if (data.code === 1) {
            this.dialogTableVisibleEdit = false;
            this.$message({type: 'success', message: data.msg});

            this.$refs.table.reload();
            this.$refs.table1.reload();
          } else {
            this.$message.error(data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });

      },
      handlePass(row){
        if(row.type==0){
          this.$confirm('确认通过该审核吗?', '提示', {type: 'warning'}).then(() => {
            const loading = this.$loading({lock: true});
            this.$http.post('/voice/channelType',{id:row.id,type:1}).then(res=>{
              loading.close();
              let data=res.data
              if (data.code === 1) {
                this.$message({type: 'success', message:'审核通过'});
                this.$refs.table.reload();
              } else {
                this.$message.error(data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          })
        }
      },
      handleReject(row){
        if(row.type==0){
          this.$confirm('确认拒绝该审核吗?', '提示', {type: 'warning'}).then(() => {
            const loading = this.$loading({lock: true});
            this.$http.post('/voice/channelType',{id:row.id,type:2}).then(res=>{
              loading.close();
              let data=res.data
              if (data.code === 1) {
                this.$message({type: 'success', message:'拒绝审核'});
                this.$refs.table.reload();
              } else {
                this.$message.error(data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          })
        }

      },
      type(){},
      /**
       *选择省
       **/
      handleChangeProv(e){
        //console.log(e)
        /** 获取被选省份的pid**/
        let pid=''
        this.provArr.forEach(function(item){
          if(item.pid==e){
            pid=item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list',{pid:pid}).then(res=>{
          let data=JSON.parse(res.data)
          this.cityArr=data
          /** 选择省份清空市县**/
          if(this.active==1){
            this.table.where.cid=''
            this.table.where.aid=''
          }else if(this.active==2){
            this.table1.where.cid=''
            this.table1.where.aid=''
          }else{
            this.table2.where.cid=''
            this.table2.where.aid=''
          }
         
        })
      },
      /**
       *选择市
       **/
      handleChangeCity(e){
			if(e){
        /** 获取被选市的cid**/
        let cid=''
        this.cityArr.forEach(function(item){
          if(item.cid==e){
            cid=item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list',{cid:cid}).then(res=>{
          let data=JSON.parse(res.data)
          this.districtArr=data
          /** 选择省份清空县**/
           if(this.active==1){
            this.table.where.aid=''
          }else if(this.active==2){
            this.table1.where.aid=''
          }else{
            this.table2.where.aid=''
          }
        })
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
					this.table1.where.pid = ''
					this.table1.where.aid = ''
					this.table2.where.pid = ''
					this.table3.where.aid = ''
				}
      },
      //开启、关闭
      pause_start(row){
        if(row.open==2){
            this.$confirm('确定暂停该频道吗?', '提示', {type: 'warning'}).then(() => {
                const loading = this.$loading({lock: true});
                this.$http.post('/voice/channelStatus', {id:row.id,open:1}).then(res => {
                  let data=res.data
                  //console.log(data)
                    loading.close();
                    if (data.code === 1) {
                        this.$message({type: 'success', message: '暂停成功'});
                        this.$refs.table.reload();
                        this.$refs.table1.reload();
                    } else {
                        this.$message.error(data.msg);
                    }
                }).catch(e => {
                    loading.close();
                    this.$message.error(e.message);
                });
            })
          }else{
              this.$confirm('确定开启该频道吗?', '提示', {type: 'warning'}).then(() => {  
                  const loading = this.$loading({lock: true});
                  this.$http.post('/voice/channelStatus', {id:row.id,open:2}).then(res => {
                    loading.close();
                    let data=res.data
   
                      if (data.code === 1) {
                          this.$message({type: 'success', message: '开启成功'});
                          this.$refs.table.reload();
                          this.$refs.table1.reload();
                      } else {
                          this.$message.error(data.msg);
                      }
                  }).catch(e => {
                      loading.close();
                      this.$message.error(e.message);
                  });
              })
          }
      },

        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        // beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;
            //
            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
            // }
        }
}
</script>

<style scoped>
    .ele-block .el-upload-dragger {
        width: 100%;
    }
    .changeStyle{
        width:150px;margin-right:30px;
    }
    .ele-body{
        padding:15px;
    }
    .el-upload-list el-upload-list--text{display:none !important}
    /deep/.el-tabs__item{
        padding:0 20px !important;
        font-size: 15px;
    }
    /deep/.el-tabs__nav-scroll{
        background: white !important;
        padding-top: 10px;
    }
    .user-info-tabs >>> .el-tabs__nav-wrap {
        padding-left: 20px;
    }
    /*/deep/.el-dialog .el-dialog__body{*/
    /*    !*background-color: white !important;*!*/
    /*    padding-left: 20px;*/
    /*}*/
    .avatar-uploader .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
        border: 1px dashed #d9d9d9;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
		/deep/.el-dialog{
			margin-top: 80px !important;
		}
</style>

